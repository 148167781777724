import React from "react";
import "../pages/main.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useData } from "../contexts/datacontext";


export default function Footer() {

    const { general } = useData();

    console.log(general)

    return(
        <>
            <div class="footer">
                <div class="footerTop">
                    <div class="footerTopRow">
                        <div class="footer-menu">
                            <div class="footer-menu-title">Kontakt</div>
                            <div class="footer-menu-item">{general.firm_name}</div>
                            <div class="footer-menu-item">{general.address?.street}&nbsp;{general.address?.housenumber}</div>
                            <div class="footer-menu-item" style={{marginBottom: "20px"}}>{general.address?.postleitzahl}&nbsp;{general.address?.city}</div>
                            <div class="footer-menu-item"> <FontAwesomeIcon icon={faPhone} /> {general.phone}</div>
                            <div class="footer-menu-item"> <FontAwesomeIcon icon={faEnvelope} /> {general.mail}</div>
                        </div>
                        <div className="footer-menu" style={{alignItems: "flex-end"}}>
                            <div className="footer-menu-title">Geschäftszeiten</div>
                            {general.opening_times?.map((opening_hour, index) => (
                                <div className="flex justifyBetween gap3" key={index}>
                                    <div className="footer-menu-item">{opening_hour.days}</div>
                                    <div className="flex gap3">
                                        {opening_hour.times?.map((time, subIndex) => (
                                        <div className="flex gap2" key={subIndex}>
                                            <div className="footer-menu-item">{time.opening}</div>
                                            <div>-</div>
                                            <div className="footer-menu-item">{time.closing}</div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div class="footerBottom">
                    <div>© {new Date().getFullYear()} {general.firm_name}</div>
                    <div class="flex gap2">
                        <Link to="/legal-notice">Impressum</Link>
                        <Link to="/privacy-policy">Datenschutzerklärung</Link>
                    </div>
                </div>
            </div>
        </>
    )
}