import React, { useState } from "react";
import "./main.css";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useData } from "../contexts/datacontext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Projectbox from "../widgets/projectBox";


export default function Service() {
    const { services, projects, teamMembers, partners, uploadsLocation, general } = useData();
    const { id } = useParams();

    const service = services.find(s => s._id === id);

    const [currentIndex, setCurrentIndex] = useState(0);

    if (!service) {
        return <div>Service not found.</div>;
    }
    
    const sliderImages = service.images.slice(1);
    
    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? sliderImages.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === sliderImages.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    const relatedProjects = projects.filter(project => 
        project.services.some(projService => projService._id === service._id)
    );

    const servicePartners = service.partners.map(a => partners.find(b => a._id === b._id));

    const responsiblePerson = teamMembers.find(member => member._id === service.responsiblePerson._id);

    return (
        <>
            <Helmet>
                <title>{service.title} - Rolef</title>
            </Helmet>
            <img src={uploadsLocation + service.images[0].path} alt={service.title} className="service-img" />
            <div className="pageContent">
                <div className="main-section">
                    <div className="pageTitle">{service.title}</div>
                    <div className="text-section" dangerouslySetInnerHTML={{ __html: service.description }}/>
                </div>
                {service._id === "8f1ab3c5636432264f0000a2" && <div class="alarmbox">
                    <div className="huge">Du hast dich ausgeschlossen und brauchst schnellstmöglich Hilfe?</div>
                    <div className="font600 massive">{general.phone}</div>
                    <div className="notruf"> Außerhalb der Arbeitszeiten: {general.notruf_nummer}</div>
                </div>}

                {sliderImages > 0 && <div className="image-slider">
                    <button onClick={handlePrevClick} className="slider-button">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <div className="slider-container">
                        <div className="slider-wrapper">
                            <img 
                                src={uploadsLocation + sliderImages[currentIndex].path} 
                                alt="project-image" 
                                className="bigImageProject"
                            />
                        </div>
                        <div className="dots-container">
                            {sliderImages.map((_, index) => (
                                <span 
                                    key={index} 
                                    className={`dot ${index === currentIndex ? 'active' : ''}`}
                                    onClick={() => handleDotClick(index)}
                                ></span>
                            ))}
                        </div>
                    </div>
                    <button onClick={handleNextClick} className="slider-button">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>}

                {relatedProjects.length > 0 && <div className="main-section">
                    <div className="main-title">Unsere letzten Projekte</div>
                    <div className="projectsRow">
                        <Projectbox data={relatedProjects} />
                    </div>
                </div>}

                {servicePartners.length > 0 && (
                    <div className="main-section">
                        <div className="main-title">Unsere Hersteller Partner</div>
                        <div className="client-row">
                            {servicePartners.map((partner, index) => (
                                <a href={partner?.link} target="_blank" rel="noopener noreferrer">
                                    <img
                                        className="client-circle"
                                        alt={`partner-circle-${index}`}
                                        src={uploadsLocation + partner?.image.path}
                                        key={index}
                                    />
                                </a>
                            ))}
                        </div>
                    </div>
                )}

                {responsiblePerson && <div className="main-section">
                    <div className="contactpersonBox">
                        {responsiblePerson && (
                            <>
                                <img src={uploadsLocation + responsiblePerson.image.path} className="contactperson-img" alt="contactperson" />
                                <div className="contact-info">
                                    <div className="contact-intro">Ihr Ansprechpartner</div>
                                    <div className="contact-name">{responsiblePerson.name}</div>
                                    <div className="contact-contact">
                                        Telefon: <a href={`tel:${responsiblePerson.phone}`} className="font600">{responsiblePerson.phone}</a>
                                    </div>
                                    <div className="contact-contact">
                                        Email: <a href={`mailto:${responsiblePerson.email}`} className="font600">{responsiblePerson.email}</a>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>}
            </div>
        </>
    );
}