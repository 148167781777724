import React from "react";
import { Link } from "react-router-dom";
import "../pages/main.css";
import { useData } from "../contexts/datacontext";

export default function Projectbox({ data }) {

    const { clients, uploadsLocation } = useData();

    return (
        <>
            {data.map((project, index) => {
                
                const client = clients.find(c => c.id === project.client);

                return (
                    <Link to={`/project/${project._id}`} className="project-box" key={project._id}>
                        <img className="project-box-image" src={uploadsLocation + project.images[0].path} alt={`project-${project.id}`} />
                        <div className="project-box-bottom">
                            {client && ( <img className="project-box-icon" src={uploadsLocation + client.image.path} alt={`icon-client-${index}`} /> )}
                            <div className="project-box-title">{project.title}</div>
                            {client && <div>für {client.name}</div>}
                        </div>
                    </Link>
                );
            })}
        </>
    );
}