import React from "react";
import "./main.css";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { useData } from "../contexts/datacontext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import Projectbox from "../widgets/projectBox";

export default function TeamMember() {
    const { id } = useParams();
    const { teamMembers, projects, services, uploadsLocation } = useData();

    const member = teamMembers.find(member => member._id === id);

    if (!member) {
        return <div>Team member not found</div>;
    }

    // Filtered services and projects
    const memberServices = services.filter(
        service => service.responsiblePerson._id === member._id
    );
    
    // Filtered projects: Check if the member's ID is in the 'team' array of the project
    const memberProjects = projects.filter(project =>
        project.team.some(teamMember => teamMember._id === member._id)
    );

    console.log(projects)

    return (
        <>
            <Helmet>
                <title>{member.name} - Team</title>
            </Helmet>
            <div className="pageContent">
                <div key={member.id} style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "30px", width: "100%" }}>
                    <div className="flex flexCol alignCenter">
                        <div className="team-member-name">{member.name}</div>
                        <div className="team-member-position">{member.position}</div>
                    </div>
                    <img src={uploadsLocation + member.image.path} alt={member.name} className="team-member-img" />
                    <div className="team-member-description" dangerouslySetInnerHTML={{ __html: member.description }} />

                    {/* Updated services rendering */}
                    <div>
                        <div style={{ marginBottom: "10px", fontSize: "var(--fontsize-large)"}}>Spezialist für:</div>
                        <div className="projectsRow" style={{ justifyContent: "flex-start" }}>
                            {memberServices.map((service) => (
                                <Link to={`/service/${service.id}`} className="image-wrapper" key={service.id}>
                                    <img alt={service.title} className="image-container" src={uploadsLocation + service.images[0].path} />
                                    <div className="overlay">
                                        <div className="service-box-bottom">
                                            <div>{service.title}</div>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>

                    {/* Projects section */}
                    <div>
                        <div style={{ marginBottom: "10px" }}>Hat an folgenden Projekten mitgearbeitet:</div>
                        <div className="projectsRow" style={{ justifyContent: "flex-start" }}>
                            <Projectbox data={memberProjects} />
                        </div>
                    </div>

                    {/* Contact Information */}
                    <div className="member-contact-box">
                        <div>Kontaktdaten</div>
                        <div className="flex" style={{justifyContent: "space-around"}}>
                            <div className="flex gap2">
                                <div><FontAwesomeIcon icon={faEnvelope} /></div>
                                <div>{member.email}</div>
                            </div>
                            <div className="flex gap2">
                                <div><FontAwesomeIcon icon={faPhone} /></div>
                                <div>Telefonnummer: {member.phone}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
