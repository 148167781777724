import React, { useState } from "react";
import "../pages/main.css";
import { Helmet } from "react-helmet";


export default function SchluesselFormular() {
    const [selectedRepairOption, setSelectedRepairOption] = useState("");
    
    // Track uploaded images
    const [saveContactData, setSaveContactData] = useState(false);

    // Handle button click to show selected style
    const handleRepairOptionClick = (option) => {
        setSelectedRepairOption(option);
    };

    const handleCheckboxChange = () => {
        setSaveContactData((prev) => !prev);
    };

    return(
        <>
            <Helmet>
                <title>Formular</title>
            </Helmet>
            <div class="pageContent">
                <div class="pageHeader">
                    <div class="pageTitle">Schlüsselbestellung</div>
                    <div class="pageSubtitle">Hier kannst du schnell und leicht deine Anfrage stellen. Wir melden uns innerhalb von 48h bei dir.</div>
                </div>
                <div class="formular-box">
                    <div className="fomular-section">
                        <div className="fomular-header">Informationen</div>
                        <div className="fomular-section-content">

                            <div>
                                <div className="inputLabel">Um welches Objekt handelt es sich?</div>
                                <input type="text" className="inputField" placeholder="z.B. Severinsstraße 8, 28392 Köln" />
                            </div>

                            <div>
                                <div className="inputLabel">Was ist die Herstellermarke?</div>
                                <div className="flex" style={{flexDirection: "column"}}>
                                    <div className="button-options">
                                        {["DOM", "CES", "EVVA", "ZIKON", "BAB", "BKS", "WINK HAUS", "ABUS", "WILKA", "KESO", "INSITECH", "SONSTIGE"].map(option => (
                                            <div 
                                            key={option} 
                                            className={`button-fomular ${selectedRepairOption === option ? "active" : ""}`} 
                                            onClick={() => handleRepairOptionClick(option)}
                                            >
                                                {option}
                                            </div>
                                        ))}
                                    </div>
                                    {selectedRepairOption === "SONSTIGE" && <input type="text" className="inputField" style={{marginTop: "10px", width: "150px"}} placeholder="Marke" />}
                                </div>
                            </div>

                            <div>
                                <div className="inputLabel">Was ist die Schlüsselnummer?</div>
                                <input type="text" className="inputField" placeholder="z.B. 328923" />
                            </div>

                            <div>
                                <div className="inputLabel">Was ist die Anlagennummer?</div>
                                <input type="text" className="inputField" placeholder="z.B. A-23223" />
                            </div>

                            <div>
                                <div className="inputLabel">Wie viele Schlüssel willst du bestellen?</div>
                                <input type="text" className="inputField" placeholder="z.B. 3" />
                            </div>
                        </div>
                    </div>
                    <div class="fomular-section">
                        <div class="fomular-header">Kontaktdaten</div>
                        <div className="fomular-section-content">

                            <div>
                                <div className="inputLabel">Was ist deine Kundennummer? (optional)</div>
                                <input type="text" className="inputField" placeholder="z.B. RE-232.32" />
                            </div>
                            <div>
                                <div class="inputLabel">Ansprechperson</div>
                                <input type="text" class="inputField" placeholder="z.B. Dieter Jugends" />
                            </div>
                            <div>
                                <div class="inputLabel">Firma oder HV (optional)</div>
                                <input type="text" class="inputField" placeholder="z.B. 0172 ..." />
                            </div>
                            <div>
                                <div class="inputLabel">Telefonnummer</div>
                                <input type="text" class="inputField" placeholder="z.B. 0172 ..." />
                            </div>
                            <div>
                                <div class="inputLabel">Email</div>
                                <input type="text" class="inputField" placeholder="z.B. Maxmustermannstraße 3, 54281, Musterstadt" />
                            </div>
                            <div onClick={handleCheckboxChange} style={{display: "flex", gap: "5px", alignItems: "center", cursor: "pointer"}}>
                                <input 
                                    type="checkbox" 
                                    checked={saveContactData} 
                                />
                                Kontaktangaben für das nächste Mal speichern
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: "10px", alignItems: "center"}}>
                        <div class="buttonRequest">Kostenpflichtig bestellen</div>
                        <div style={{cursor: "pointer"}}>Ich möchte bitte nur einen Kostenvoranschlag bekommen</div>
                    </div>
                </div>
            </div>
        </>
    );
}