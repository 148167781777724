import React from "react";
import "./main.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useData } from "../contexts/datacontext";


export default function Team() {

    const { teamMembers, uploadsLocation, general } = useData();

    return(
        <>
            <Helmet>
                <title>Team</title>
            </Helmet>
            <div class="pageContent">
                <div class="pageHeader">
                    <div class="pageTitle">Team</div>
                </div>
                <img src={uploadsLocation + general.team_bild.path} className="teamBild" alt={general.team_bild}/>
                <div className="team-row">
                    {teamMembers.map((member) => (
                        <Link to={`/team-member/${member._id}`} className="team-member" key={member._id}>
                            <img className="circle-member" src={uploadsLocation + member.image.path} alt={member.name} />
                            <div className="member-name">{member.name}</div>
                            <div className="member-position">{member.position}</div>
                        </Link>
                    ))}
                </div>
            </div>
        </>
    )
}