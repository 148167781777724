import React, { useRef, useState } from "react";
import "../pages/main.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useData } from "../contexts/datacontext";
import axios from "axios";


export default function ReparaturFormular() {
    const { submitLocation } = useData();
    const formRef = useRef();

    const [selectedRepairOption, setSelectedRepairOption] = useState("");
    const [selectedMaterial, setSelectedMaterial] = useState("");
    const [isCustomer, setIsCustomer] = useState(null);
    const [differentLocation, setDifferentLocation] = useState(null);
    
    // Track uploaded images
    const [images, setImages] = useState([]);
    const [saveContactData, setSaveContactData] = useState(false);

    const [formSubmitted, setFormSubmitted] = useState(false);

    // Handle button click to show selected style

    const handleDifferentLocationClick = (status) => {
        setDifferentLocation(status);
    };

    const handleImageUpload = (event) => {
        const uploadedFiles = Array.from(event.target.files);
        setImages((prevImages) => [...prevImages, ...uploadedFiles]);
    };

    const triggerFileUpload = () => {
        document.getElementById("imageUpload").click();
    };

    const handleImageDelete = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const handleCheckboxChange = () => {
        setSaveContactData((prev) => !prev);
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (formRef.current.checkValidity() === false) {
            formRef.current.reportValidity();
            alert("Bitte füllen Sie alle Pflichtfelder aus.");
            return;
        }
        const formData = new FormData(formRef.current);
        if(images.length) {
            formData.append("Bilder", images.length + " angehangen");
            images.forEach((image, index) => {
                formData.append(`_images[${index}]`, image);
            });
        }
    
        axios.post(submitLocation + "/request-form.php", formData)
            .then(() => setFormSubmitted(true))
            .catch(e => console.error(e));
    };

    return(
        <>
            <Helmet>
                <title>Formular</title>
            </Helmet>
            <div class="pageContent">
                <div class="pageHeader">
                    <div class="pageTitle">Reparaturanfrage</div>
                    <div class="pageSubtitle">Hier können Sie schnell und leicht Ihre Anfrage stellen. Wir melden uns innerhalb von 48h bei Ihnen.</div>
                </div>
                {formSubmitted ? (
                    <div className="flex flexCol alignCenter gap4">
                        <div style={{textAlign: "center"}}>Vielen Dank, Ihre Anfrage wurde erfolgreich uns übermittelt. Wir melden uns schnellstmöglich bei Ihnen.</div>
                        <Link to="/">Home</Link>
                    </div>
                ) : (
                    <form class="formular-box" ref={formRef}>
                        <div className="fomular-section">
                            <div className="fomular-header">Informationen</div>
                            <div className="fomular-section-content">
                                <div>
                                    <div className="inputLabel">Was muss repariert werden?</div>
                                    <div className="button-options">
                                        {["Tür", "Fenster", "Briefkasten", "Sonstiges"].map(option => <>
                                            <input
                                                key={option}
                                                type="radio"
                                                name="Reparatur"
                                                id={"Reparatur-" + option}
                                                value={option}
                                                style={{display: "none"}}
                                                onChange={(e) => setSelectedRepairOption(e.target.value)}
                                                required
                                            />
                                            <label for={"Reparatur-" + option} className="button-formular">
                                                {option}
                                            </label>
                                        </>)}
                                    </div>
                                    {selectedRepairOption === "Sonstiges" && <input type="text" className="inputField" style={{marginTop: "10px", width: "150px"}} placeholder="z.B. Tresor" name="Reparatur" required />}
                                </div>

                                {selectedRepairOption === "Briefkasten" && (
                                    <div>
                                        <div className="inputLabel">Was ist die Briefkasten-ID?</div>
                                        <input type="text" className="inputField" placeholder="z.B. RE-232.32" name="Briefkasten-ID" />
                                    </div>
                                )}

                                <div>
                                    <div className="inputLabel">Um welches Material handelt es sich?</div>
                                    <div className="button-options">
                                        {["Holz", "Kunststoff", "Aluminium", "Sonstiges"].map(material => <>
                                            <input
                                                key={material} 
                                                type="radio"
                                                name="Material"
                                                id={"Material-" + material}
                                                value={material}
                                                style={{display: "none"}}
                                                onChange={(e) => setSelectedMaterial(e.target.value)}
                                                required
                                            />
                                            <label for={"Material-" + material} className="button-formular">
                                                {material}
                                            </label>
                                        </>)}
                                    </div>
                                    {selectedMaterial === "Sonstiges" && <input type="text" className="inputField" style={{marginTop: "10px", width: "150px"}} placeholder="z.B. Glass" name="Material" />}
                                </div>

                                <div>
                                    <div className="inputLabel">Beschreibung</div>
                                    <textarea type="text" className="inputField" name="Beschreibung" placeholder="z.B. Ich kann den Schlüssel nicht mehr drehen" required />
                                </div>

                                <div>
                                    <div className="inputLabel">Fotos</div>
                                    <div className="addButton" onClick={triggerFileUpload}>+</div>
                                    <input 
                                        type="file" 
                                        id="imageUpload" 
                                        style={{ display: "none" }} 
                                        multiple 
                                        onChange={handleImageUpload}
                                        name="Fotos"
                                    />
                                    <div className="uploadedImages">
                                        {images.map((image, index) => (
                                            <div key={index} className="uploadedImage" style={{ position: "relative", display: "inline-block" }}>
                                                <img 
                                                    src={URL.createObjectURL(image)} 
                                                    alt={image.name} 
                                                    className="image-uploaded"
                                                />
                                                <button onClick={() => handleImageDelete(index)} className="cancelButton">
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fomular-section">
                            <div class="fomular-header">Kontaktdaten</div>
                            <div className="fomular-section-content">
                                <div>
                                    <div className="inputLabel">Sind Sie bereits Kunde?</div>
                                    <div className="button-options">
                                        {["Ja", "Nein"].map(status => <>
                                            <input
                                                key={status}
                                                type="radio"
                                                name="Kunde"
                                                id={"Kunde-" + status}
                                                value={status}
                                                style={{display: "none"}}
                                                onChange={(e) => setIsCustomer(e.target.value)}
                                                required
                                            />
                                            <label for={"Kunde-" + status} className="button-formular">
                                                {status}
                                            </label>
                                        </>)}
                                    </div>
                                </div>
                                {isCustomer === "Ja" && (
                                    <div>
                                        <div className="inputLabel">Was ist Ihre Kundennummer?</div>
                                        <input type="text" className="inputField" name="Kundennummer" placeholder="z.B. RE-232.32" />
                                    </div>
                                )}
                                
                                        <div>
                                            <div class="inputLabel">Vor- & Nachname</div>
                                            <input type="text" class="inputField" name="Name" placeholder="z.B. 0172 ..." required />
                                        </div>
                                        <div>
                                            <div class="inputLabel">Firma (optional)</div>
                                            <input type="text" class="inputField" name="Firma" placeholder="z.B. 0172 ..." />
                                        </div>
                                        <div>
                                            <div class="inputLabel">Straße & Hausnummer</div>
                                            <input type="text" class="inputField" name="Adresse" placeholder="z.B. 0172 ..." required />
                                        </div>
                                        <div>
                                            <div class="inputLabel">Postleitzahl</div>
                                            <input type="text" class="inputField" name="PLZ" placeholder="z.B. 0172 ..." required />
                                        </div>
                                        <div>
                                            <div class="inputLabel">Ort</div>
                                            <input type="text" class="inputField" name="Ort" placeholder="z.B. 0172 ..." required />
                                        </div>
                                        <div>
                                            <div class="inputLabel">Telefonnummer</div>
                                            <input type="text" class="inputField" name="Telefonnummer" placeholder="z.B. 0172 ..." required />
                                        </div>
                                        <div>
                                            <div class="inputLabel">Email</div>
                                            <input type="text" class="inputField" name="Email" placeholder="z.B. Maxmustermannstraße 3, 54281, Musterstadt" required />
                                        </div>
                                        <div onClick={handleCheckboxChange} style={{display: "flex", gap: "5px", alignItems: "center", cursor: "pointer"}}>
                                            <input 
                                                type="checkbox" 
                                                checked={saveContactData} 
                                            />
                                            Kontaktangaben für das nächste Mal speichern
                                        </div>
                                    
                            </div>
                        </div>
                        <div className="fomular-section">
                            <div className="fomular-header">Reparaturort</div>
                            <div className="fomular-section-content">
                                <div>
                                    <div class="inputLabel">Unterscheidet sich der Reparaturort von den Kontaktdaten?</div>
                                    <div className="button-options">
                                        {["Ja", "Nein"].map(status => <>
                                            <input
                                                key={status}
                                                type="radio"
                                                name="Reparaturort"
                                                id={"Reparaturort-" + status}
                                                value={status}
                                                style={{display: "none"}}
                                                onChange={(e) => handleDifferentLocationClick(e.target.value)}
                                                required
                                            />
                                            <label for={"Reparaturort-" + status} className="button-formular">
                                                {status}
                                            </label>
                                        </>)}
                                    </div>
                                </div>
                                {differentLocation === "Ja" && <>
                                    <div>
                                        <div class="inputLabel">Vor- & Nachname</div>
                                        <input type="text" class="inputField" name="Reparaturort Name" placeholder="z.B. 0172 ..." />
                                    </div>
                                    <div>
                                        <div class="inputLabel">Straße & Hausnummer</div>
                                        <input type="text" class="inputField" name="Reparaturort Adresse" placeholder="z.B. 0172 ..." />
                                    </div>
                                    <div>
                                        <div class="inputLabel">Postleitzahl</div>
                                        <input type="text" class="inputField" name="Reparaturort PLZ" placeholder="z.B. 0172 ..." />
                                    </div>
                                    <div>
                                        <div class="inputLabel">Ort</div>
                                        <input type="text" class="inputField" name="Reparaturort Ort" placeholder="z.B. 0172 ..." />
                                    </div>
                                    <div>
                                        <div class="inputLabel">Telefonnummer</div>
                                        <input type="text" class="inputField" name="Reparaturort Telefonnummer" placeholder="z.B. 0172 ..." />
                                    </div>
                                    <div>
                                        <div class="inputLabel">Email</div>
                                        <input type="text" class="inputField" name="Reparaturort Email" placeholder="z.B. Maxmustermannstraße 3, 54281, Musterstadt" />
                                    </div>
                                </>}
                            </div>
                        </div>
                        <button class="buttonRequest" onClick={handleSubmit}>Anfrage senden</button>
                    </form>
                ) }
            </div>
        </>
    );
}