import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./main.css";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useData } from "../contexts/datacontext";
import Projectbox from "../widgets/projectBox";

export default function Project() {
    const { id } = useParams(); // Extract project id from the URL
    const { projects, teamMembers, partners, clients, uploadsLocation } = useData(); // Assuming teamMembers is available in your context

    // Hooks must be called unconditionally
    const [detailVisibility, setDetailVisibility] = useState({
        kunde: true,
        facts: true,
        team: true,
        producer: true,
    });

    const [currentIndex, setCurrentIndex] = useState(0);

    // Find the project by matching the id from the URL
    const project = projects.find(proj => proj._id === id);

    if(!project) return;

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? project.images.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === project.images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const toggleDetail = (section) => {
        setDetailVisibility((prevVisibility) => ({
            ...prevVisibility,
            [section]: !prevVisibility[section],
        }));
    };

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    const team = project.team.map(a => teamMembers.find(b => a._id === b._id));

    const producer = project.used_producer.map(a => partners.find(b => a._id === b._id));

    const similarProjects = projects.filter(proj => proj._id !== id);

    const client = project.clients.map(a => clients.find(b => a._id === b._id));

    return (
        <>
            <Helmet>
                <title>{project.title}</title>
            </Helmet>
            <div className="pageContent">
                {/* Image Slider */}
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className="image-slider">
                        <button onClick={handlePrevClick} className="slider-button">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <div className="slider-container">
                            <div className="slider-wrapper">
                                <img 
                                    src={uploadsLocation + project.images[currentIndex].path} 
                                    alt="project-image" 
                                    className="bigImageProject"
                                />
                            </div>
                            <div className="dots-container">
                                {project.images.map((_, index) => (
                                    <span 
                                        key={index} 
                                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                                        onClick={() => handleDotClick(index)}
                                    ></span>
                                ))}
                            </div>
                        </div>
                        <button onClick={handleNextClick} className="slider-button">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>

                    {/* Project Details */}
                    <div className="project-details">
                        <div className="project-title">{project.title}</div>
                        <div className="project-description" dangerouslySetInnerHTML={{ __html: project.description }} />
                        
                        {/* Kunde Section */}
                        {project.client.length > 0 && (

                            <div className="project-detail-box">
                                <div
                                    className="project-clickarea"
                                    onClick={() => toggleDetail("kunde")}
                                >
                                    <div className="project-detail-box-title">Kunde</div>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                                {detailVisibility.kunde && (
                                    <div className="project-description">
                                        {client.map((client, index) => (
                                            <div className="team-involved-row" key={index}>
                                                <img src={uploadsLocation + client?.image.path} alt={project.client} className="team-involved-icon"/>
                                                <div className="large">{client?.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Fakten & Zahlen Section */}
                        {project.facts.length > 0 && <div className="project-detail-box">
                            <div className="project-clickarea" onClick={() => toggleDetail("facts")}>
                                <div className="project-detail-box-title">Fakten & Zahlen</div>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </div>
                            {detailVisibility.facts && (
                                <div className="project-description">
                                    {project.facts.map((fact, index) => (
                                        <div className="flex" key={index}>
                                            <div className="factTitle">{fact.title}:
                                                <span className="factData">&nbsp;{fact.data}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>}

                        {/* Producer Section */}
                        {producer?.length > 0 && <div className="project-detail-box">
                            <div className="project-clickarea" onClick={() => toggleDetail("producer")}>
                                <div className="project-detail-box-title">Eingebaute Hersteller</div>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </div>
                            {detailVisibility.producer && (
                                <div className="project-description">
                                    {producer.map((prod, index) => (
                                        <div key={index} className="team-involved-row">
                                            <img src={uploadsLocation + prod?.image.path} alt={prod?.name} className="team-involved-icon"/>
                                            <div className="large">{prod?.name}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>}

                        {/* Team Section */}
                        {team.length > 0 && <div className="project-detail-box">
                            <div className="project-clickarea" onClick={() => toggleDetail("team")}>
                                <div className="project-detail-box-title">Team</div>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </div>
                            {detailVisibility.team && (
                                <div className="project-description">
                                    {team.map((member, index) => (
                                        <div key={index} className="team-involved-row">
                                            <img src={uploadsLocation + member?.image.path} alt={member?.name} className="team-involved-icon"/>
                                            <div className="large">{member?.name}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>}
                    </div>
                </div>

                {/* Additional Projects */}
                <div className="flex flexCol gap3 w-full">
                    <div className="moreProjects">Weitere Projekte</div>
                    <div className="horizontalProjects">
                        <Projectbox data={similarProjects}/>
                    </div>
                </div>
            </div>
        </>
    );
}
