import React, { useEffect, useState } from "react";
import "./main.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useData } from "../contexts/datacontext";
import Projectbox from "../widgets/projectBox";
import Marquee from "react-fast-marquee";

export default function Home() {

    const { services, teamMembers, projects, partners, clients, uploadsLocation, general } = useData();

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (general.start_images?.length) {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % general.start_images.length);
            }
        }, 3000);
    
        return () => clearInterval(interval);
    }, [general.start_images]);

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    const [visibleQuestion, setVisibleQuestion] = useState(null);

    const toggleAnswer = (index) => {
        setVisibleQuestion((prev) => (prev === index ? null : index));
    };

    console.log(general)

    return (
        <>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <div className="image-slider">
                <div className="slider-container" style={{ width: "100%" }}>
                    <div
                        className="slider-wrapper"
                        style={{
                            transform: `translateX(-${currentIndex * 100}%)`,
                            transition: "transform 0.7s ease-in-out",
                            display: "flex",
                            width: `${(general.start_images?.length || 0) * 100}%`,
                        }}
                    >
                        {general.start_images?.map((image, index) => (
                            <img
                                key={index}
                                src={uploadsLocation + image.path} // Dynamically construct the full path
                                alt={`Slide ${index + 1}`}
                                className="backgroundImg"
                                style={{ flex: "0 0 100%" }}
                            />
                        ))}
                    </div>
                    <div className="dots-container">
                        {general.start_images?.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === currentIndex ? "active" : ""}`}
                                onClick={() => handleDotClick(index)}
                            ></span>
                        ))}
                    </div>
                </div>
            </div>
            <div className="mainContent">
                <div className="whiteSection">
                    <div className="main-section">
                        <div className="massive textCenter" dangerouslySetInnerHTML={{ __html: general.intro }} />
                        <Link className="quickRequest" to="/reparatur-formular">
                            <div className="quickRequestTitle">Reparaturanfrage</div>
                            <div className="quickRequestSubtitle">Du hast ein dringendes Problem? Kontaktiere uns hier um die schnellste Hilfe zu bekommen</div>
                        </Link>
                    </div>
                </div>
                <div className="lightSection">
                    <div className="main-section">
                        <div className="main-title">Unsere Dienstleistungen</div>
                        <div className="projectsRow">
                            {services.slice(0, 6).map((service) => (
                                <Link to={`/service/${service._id}`} className="image-wrapper" key={service._id}>
                                    <img
                                        alt={service.title}
                                        className="image-container"
                                        src={uploadsLocation + service.images[0].path}
                                    />
                                    <div className="overlay">
                                        <div className="service-box-bottom">
                                            <div>{service.title}</div>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div>Weitere Dienstleistungen ansehen</div>
                    </div>
                </div>
                <div className="whiteSection">
                    <div className="main-section">
                        <Marquee>
                            {clients.map((client, index) => (
                                <img className="client-circle ml-10" alt={`client-circle-${index}`} src={uploadsLocation + client.image.path} key={index} />
                            ))}
                        </Marquee>
                    </div>
                </div>
                <div className="lightSection">
                    <div className="main-section">
                        <div className="main-title">Unsere Projekte</div>
                        <div className="projectsRow">
                            <Projectbox data={projects} />
                        </div>
                        <Link to="/projects" className="seeAll">Weitere Projekte ansehen</Link>
                    </div>
                </div>
                <div className="whiteSection">
                    <div className="main-section">
                        <div className="main-title">Unser Team</div>
                        <div className="team-row">
                            {teamMembers.map((member) => (
                                <Link to={`/team-member/${member._id}`} className="team-member" key={member._id}>
                                    <img className="circle-member" src={uploadsLocation + member.image.path} alt={member.name} />
                                    <div className="member-name">{member.name}</div>
                                    <div className="member-position">{member.position}</div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="whiteSection">
                    <div className="main-section">
                        <div className="main-title">Häufige Fragen</div>
                        <div className="questionMAIN">
                            {general.faq?.map((faq, index) => (
                                <div className="questionBox" key={index}>
                                <div
                                    className="question"
                                    onClick={() => toggleAnswer(index)}
                                    style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    }}
                                >
                                    <div>{faq.question}</div>
                                    <FontAwesomeIcon
                                    icon={visibleQuestion === index ? faChevronUp : faChevronDown}
                                    />
                                </div>
                                {visibleQuestion === index && <div className="answer">{faq.answer}</div>}
                                </div>
                            ))}
                        </div>
                        <div>Weitere Fragen</div>
                    </div>
                    <div className="main-section">
                        <div className="main-title">Unsere Partner</div>
                        <div className="client-row">
                            {partners.map((partner, index) => (
                                <a href={partner?.link} target="_blank" rel="noopener noreferrer" className="partner-box">
                                    <img className="client-circle" alt={`partner-circle-${index}`} src={uploadsLocation + partner.image.path} key={index} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}