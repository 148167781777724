import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const cmsLocation = process.env.NODE_ENV === "development" ? "" : "/cms";
    const uploadsLocation = cmsLocation + "/storage/uploads";
    const submitLocation = cmsLocation + "/submit";

    const[services, setServices] = useState([]);
    useEffect(() => {
        axios.get(cmsLocation + "/api/content/items/services")
            .then(res => setServices(res.data))
            .catch(e => console.error(e));
    }, []);

    const [teamMembers, setTeamMembers] = useState([]);
    useEffect(() => {
        axios.get(cmsLocation + "/api/content/items/members")
            .then(res => setTeamMembers(res.data))
            .catch(e => console.error(e));
    }, []);


    const[projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get(cmsLocation + "/api/content/items/projects")
            .then(res => setProjects(res.data))
            .catch(e => console.error(e));
    }, []);


    const [partners, setPartners] = useState([]);
    useEffect(() => {
        axios.get(cmsLocation + "/api/content/items/partners")
            .then(res => setPartners(res.data))
            .catch(e => console.error(e));
    }, []);
    

    const [clients, setClients] = useState([]);
    useEffect(() => {
        axios.get(cmsLocation + "/api/content/items/clients")
            .then(res => setClients(res.data))
            .catch(e => console.error(e));
    }, []);

    const [general, setGeneral] = useState("");
    useEffect(() => {
        axios.get(cmsLocation + "/api/content/item/general")
            .then(res => setGeneral(res.data))
            .catch(e => console.error(e));
    }, []);
    

    return (
        <DataContext.Provider value={{ services, teamMembers, projects, partners, clients, general, uploadsLocation, submitLocation }}>
            {children}
        </DataContext.Provider>
    );
};


export const useData = () => {
    return useContext(DataContext);
};