import React from "react";
import "./main.css";
import { Helmet } from "react-helmet";
import { useData } from "../contexts/datacontext";
import Projectbox from "../widgets/projectBox";

export default function Projects() {
    const { projects } = useData();

    return (
        <>
            <Helmet>
                <title>Projekte</title>
            </Helmet>
            <div className="pageContent">
                <div className="pageHeader">
                    <div className="pageTitle">Projekte</div>
                    <div className="pageSubtitle">Hier siehst du eine Liste einiger unserer vergangenen Projekte.</div>
                </div>
                <div className="projectsRow">
                    <Projectbox data={projects} />
                </div>
            </div>
        </>
    );
}
