import { Route, Routes, useLocation} from "react-router-dom";
import Navbar from "./widgets/navbar";
import Footer from "./widgets/footer";
import Home from "./pages/home";
import PrivacyPolicy from "./pages/privacy_policy";
import LegalNotice from "./pages/legal_notice";
import Projects from "./pages/projects";
import Project from "./pages/project";
import ReparaturFormular from "./formulare/reparatur_formular";
import Services from "./pages/services";
import Team from "./pages/team";
import TeamMember from "./pages/team_member";
import Service from "./pages/service";
import SchluesselFormular from "./formulare/schluessel_formular";
import Formulare from "./pages/formulare";
import { useLayoutEffect } from "react";

export default function App() {

  //scroll to top on route change
  const location = useLocation();
  useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="App">
      <Navbar />
        <Routes>
            <Route path="/home" element={<Home />} />
            
            <Route path="/legal-notice" element={<LegalNotice/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />

            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:id" element={<Project />} />

            <Route path="/team" element={<Team />} />
            <Route path="/team-member/:id" element={<TeamMember />} />
            
            <Route path="/services" element={<Services />} />
            <Route path="/service/:id" element={<Service />} />
            
            <Route path="/reparatur-formular" element={<ReparaturFormular />} />
            <Route path="/schluessel-formular" element={<SchluesselFormular />} />

            {/* <Route path="/formulare" element={<Formulare />} /> */}

            <Route path="/" element={<Home />} />
        </Routes>
      <Footer />
    </div>
  );
}