import React, { useEffect, useState } from "react";
import "../pages/main.css";
import "./navbar.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faBars } from "@fortawesome/free-solid-svg-icons";
import { useData } from "../contexts/datacontext";

export default function Navbar() {
  const { services, general, uploadsLocation } = useData();
  const location = useLocation();

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [activeLink, setActiveLink] = useState("/home");

  useEffect(() => {
    setActiveLink(location.pathname); // Update active link based on current route
  }, [location.pathname]);

  const toggleDropdown = () => setDropdownVisible((prev) => !prev);
  const closeDropdown = () => setDropdownVisible(false);
  const toggleMenu = () => setMenuVisible((prev) => !prev);
  const closeMenu = () => setMenuVisible(false);

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        boxShadow: "1px 0px 10px var(--grey)",
        zIndex: "2",
      }}
    >
      <div className="headerContact">
        <div className="flex gap">
          <FontAwesomeIcon icon={faPhone} />
          <div>{general.phone}</div>
        </div>
        <div className="flex gap">
          <FontAwesomeIcon icon={faEnvelope} />
          <div>{general.mail}</div>
        </div>
      </div>

      <div className="headerTop">
        <Link to="/home">
          <img
            src={uploadsLocation + general.logo?.path}
            className="logo"
            alt="rolef_logo"
          />
        </Link>

        <div className="mobile-menu-icon" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </div>

        <div
          className={`flex ${isMenuVisible ? "show-menu" : "hide-menu"} gap4`}
        >
          <div className="navbarItems">
            <Link to="/home" className={`navbar-item ${activeLink === "/home" ? "active" : ""}`} onClick={closeMenu}>
              Home
            </Link>
            <Link
              to="/services"
              className={`navbar-item services-container ${activeLink === "/services" ? "active" : ""}`}
              onMouseEnter={toggleDropdown}
              onMouseLeave={closeDropdown}
              onClick={closeMenu} // Close menu when clicked
            >
              Dienstleistungen
              {isDropdownVisible && (
                <div className="services-dropdown">
                  {services.map((service) => (
                    <Link
                      to={`service/${service._id}`}
                      className="option-services"
                      onClick={closeMenu} // Close menu when clicked
                      key={service._id}
                    >
                      {service.title}
                    </Link>
                  ))}
                </div>
              )}
            </Link>
            <Link to="/projects" className={`navbar-item ${activeLink === "/projects" ? "active" : ""}`} onClick={closeMenu}>
              Projekte
            </Link>
            <Link to="/team" className={`navbar-item ${activeLink === "/team" ? "active" : ""}`} onClick={closeMenu}>
              Team
            </Link>

            <div
              className="active-bar"
              style={{
                left: `calc(${document.querySelector('.navbar-item.active')?.offsetLeft || 0}px)`,
                width: `${document.querySelector('.navbar-item.active')?.offsetWidth || 0}px`,
              }}
            ></div>
          </div>
        </div>
          <Link to="/reparatur-formular" className="cta-button" onClick={closeMenu}>
            Anfragen
          </Link>
      </div>
    </div>
  );
}
