import React from "react";
import "./main.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useData } from "../contexts/datacontext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";


export default function Services() {

    const { services, uploadsLocation } = useData();

    return(
        <>
            <Helmet>
                <title>Dienstleistungen</title>
            </Helmet>
            <div class="pageContent">
                <div class="pageHeader">
                    <div class="pageTitle">Dienstleistungen</div>
                    <div class="pageSubtitle">Hier siehst du eine Liste unserer Kern-Dienstleistungen.</div>
                </div>
                <div class="projectsRow">
                    {services.map((service) => (
                        <Link to={`/service/${service._id}`} className="image-wrapper" key={service._id}>
                            <img alt={service.title} className="image-container" src={uploadsLocation + service.images[0].path} />
                            <div className="overlay">
                                <div className="service-box-bottom">
                                    <div>{service.title}</div>
                                    <FontAwesomeIcon icon={faChevronRight} /> 
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </>
    )
}